module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Easy Employee Scheduling Software for Restaurants | 7shifts","short_name":"7shifts","start_url":"/","background_color":"#000000","theme_color":"#FB7448","display":"standalone","icon":"/opt/buildhome/repo/static/logo-icon.svg","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"39cabcc0632fa9351da21f4c421cd89f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
